<template>
  <v-avatar color="rgba(255,255,255, 0.97)" class="elevation-1" :size="34">
    <v-avatar tile :size="16">
      <v-img
        :width="width"
        :height="height"
        :src="`/img/technologies/icons/${icon.toLowerCase()}`"
        lazy-src="/img/icon-16x16.png"
        quality="70"
        :alt="`${title} logo`"
        contain
      >
        <template v-slot:error>
          <v-img
            width="100%"
            height="100%"
            src="/img/na-16x16.png"
            placeholder=""
            contain
          ></v-img>
        </template>
      </v-img>
    </v-avatar>
  </v-avatar>
</template>

<script setup>
const width = 16
const height = 16

defineProps({
  title: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: '',
  },
})
</script>
